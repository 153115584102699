import telemetry from '../utilities/telemetry';
import shopperNotes from '../services/ShopperNoteService';

export const includeISC = urlString => {
  try {
    const currentUrl = new URL(window.location.href);
    if (currentUrl && currentUrl.searchParams.get('isc')) {
      const targetUrlObj = new URL(urlString);
      if (targetUrlObj) {
        if (!targetUrlObj.search || !targetUrlObj.searchParams.get('isc')) {
          targetUrlObj.searchParams.set('isc', currentUrl.searchParams.get('isc'));
          return targetUrlObj.href;
        }
      }
    }
  } catch (e) {
    return urlString;
  }
  return urlString;
};

export function redirectToUrl(href) {
  if (window && href) {
    window.location.href = href;
  }
}

// todo: when selfserve experiment is over, we won't need this (because all URLs already have ISCs via makeURL)
export const redirectToExternalUrl = (urlString, newWindow = false, component = '') => {
  urlString = includeISC(urlString);

  if (component) {
    telemetry.send('url_helper', 'external_redirect', component, 'redirect', [['urlString', urlString]]);
  }

  if (newWindow) {
    window.open(urlString, '_blank');
  } else {
    window.location.href = urlString;
  }

  return urlString;
};

export const addQueryParams = (urlString, params) => {
  if (params && urlString) {
    try {
      const urlObject = new URL(urlString);
      for (const param in params) {
        if (params[param]) {
          urlObject.searchParams.set(param, params[param]);
        }
      }
      return urlObject.href;
    } catch (e) {
      return urlString;
    }
  }
  return urlString;
};

export const parseQueryParams = (urlString, searchQuery) => {
  if (urlString && searchQuery) {
    try {
      const urlObject = new URL(urlString);
      const paramObject = {};
      for (const [name, value] of urlObject.searchParams) {
        paramObject[name] = value;
      }
      return paramObject;
    } catch (e) {
      return urlString;
    }
  }
  return urlString;
};

export const addUrlPath = (urlString, pathname) => {
  if (pathname && urlString) {
    try {
      const urlObject = new URL(urlString);
      urlObject.pathname = pathname;
      return urlObject.href;
    } catch (e) {
      return urlString;
    }
  }
  return urlString;
};

export const addHashToRoute = (urlString, hash) => {
  if (hash && urlString) {
    try {
      const urlObject = new URL(urlString);
      urlObject.hash = '#/' + hash;
      return urlObject.href;
    } catch (e) {
      return urlString;
    }
  }
  return urlString;
};

export const sanitizeDomainName = name => {
  return (
    name &&
    name
      .replace(/https:\/\//, '')
      .replace(/http:\/\//, '')
      .replace(/\/$/, '')
  );
};

export const replaceURLTokens = (url, tokenPairs = []) => {
  tokenPairs.forEach(({ token, value = '' }) => {
    url = url.replace(token, value);
  });
  return url;
};

export const removeHttps = (url = '') => {
  const re = /http(s*):\/\/|\/$/g; // matches https:// http:// or ending /
  return url.replace(re, '');
};

// eslint-disable-next-line max-params
export const goToUrlWithShopperNotes = (url, productObj, config, component) => {
  // send shopperNotes note for CRM data collection
  shopperNotes.sendNote(config.csrfToken, false, productObj, productObj.id, err => {
    if (err) {
      console.error('shoppernoteservice error:', err); // eslint-disable-line no-console
    }
    redirectToExternalUrl(url, false, component);
  });
};
