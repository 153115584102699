import apiCall from './ApiCall';

const makeAccountNote = (exId = 'new', { name, type, CES: { resourceId } = {} }) => {
  return `${type}: ${name} (${resourceId}) (${exId}) entered account`;
};

const makeNote = (isManageAll, exId, productObj) => (isManageAll ? productObj + ' (Manage All) account accessed' : makeAccountNote(exId, productObj));

const ShopperNoteService = {
  // eslint-disable-next-line max-params
  sendNote: (csrfToken, isManageAll, productObj, exId, callback) => {
    if (exId === 'guid') {
      callback('');
      return;
    } // running inside a unit test
    if (!productObj) {
      callback('shoppernoteservice: subscription is missing', null);
      return;
    }
    if (!exId && !isManageAll) {
      callback('shoppernoteservice: externalId is missing', null);
      return;
    }

    const note = makeNote(isManageAll, exId, productObj);
    const apiUrl = '/products/api/shoppernotes';
    const apiHeaders = {
      'Accept': 'application/json',
      'Content-Type': 'application/json; charset=UTF-8',
      'X-CSRF-Token': csrfToken
    };

    apiCall(
      {
        params: {
          method: 'POST',
          url: apiUrl,
          headers: apiHeaders,
          data: { note }
        },
        then: response => callback(null, response),
        catch: response => callback(response, null)
      },
      'send_shopper_note'
    );
  }
};

export default ShopperNoteService;
