/* eslint-disable no-undef  */

import axios from 'axios';
import windowHolder from './api-call-window-provider';
import queryString from 'query-string';
import urlParse from 'url-parse';
import { apmClient } from './apm';

export const updateUrl = callParams => {
  const { window } = windowHolder;
  if (!window) {
    return;
  }

  const { location } = window;
  const paramUrl = urlParse(callParams.url);
  const paramHostname = paramUrl.hostname;

  const currentUrl = urlParse(location.href);
  const currentHostname = currentUrl.hostname;

  if (paramHostname !== currentHostname) {
    return;
  }

  // Copy the origin parameter from the window into the params URL.
  const { origin } = queryString.parse(currentUrl.query);
  if (origin) {
    const paramQs = queryString.parse(paramUrl.query);
    paramQs.origin = origin;
    paramUrl.query = queryString.stringify(paramQs).toString();

    callParams.url = paramUrl.toString();
  }
};

export default async function apiCall(callProps) {
  const emptyFunc = function () {};
  const DEFAULT_TIMEOUT = 30_000; // 30s timeout for API calls

  // Grab the origin querystring from the window
  updateUrl(callProps.params);

  const { method, url } = callProps.params;
  const apmTransaction = apmClient.startElasticApmTransaction(method, url);

  const params = {
    ...callProps.params,
    timeout: callProps.params.timeout ? callProps.params.timeout : DEFAULT_TIMEOUT
  };

  try {
    const response = await axios(params);
    apmClient.endSuccessfulApmTransaction(apmTransaction);
    (callProps.then || emptyFunc)(response);
  } catch (error) {
    const res = error.response || error;
    if (error) {
      apmClient.captureErrorElasticApm(`API call exception to ${method} ${url}: ${error.message}`, apmTransaction, res);
    } else {
      // just fail, don't capture the error
      apmClient.endFailedApmTransaction(apmTransaction);
    }
    (callProps.catch || emptyFunc)(res);
  }
}
