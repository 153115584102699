import React from 'react';
import { redirectToExternalUrl } from '../utilities/UrlHelper';
import { makeURL } from '../src/account-products/config/parsers/url_config_parser';

const Profile = () => {

  React.useEffect(() => {
    redirectToExternalUrl(makeURL('ssoProfile'), false, 'profile');
  }, []);

  return (
    <></>
  );
};

export default Profile;
